<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import ListingTable from "@/components/listings/listing-table";
// import { badgeColor, statusLabel } from "@/util/mapfunction.js";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";
import InputGroup from "@/components/forms/input-group";
import SelectGroup from "@/components/forms/select-group";
// import Multiselect from 'vue-multiselect'
// import DatePicker from 'vue2-datepicker';
import StatusBadge from "@/components/buttons/tenancy-status";

export default {
    components: {
        Layout,
        PageHeader,
        ListingTable,
        ConfirmationModal,
        MessageModal,
        // Multiselect,
        InputGroup,
        SelectGroup,
        // DatePicker,
        StatusBadge
    },
    data() {
        // const id = this.$route.params.id;
        return {
            title: `CTC`,
            items: [
                {
                    text: "CTC"
                },
            ],
            // id: id,
            filter: {
                "order_display_id": "",
                "parent_id": "",
                "status": 'all',
                "created": ""
            },
            request_date: '',
            fields: [
                { key: "ctc.order_display_id", sortable: false, label: "ID" },
                // { key: "tenancy_parent", sortable: false, label: "Tenancy ID",
                //     formatter: value => {
                //         if (value != null) {
                //             return value.order_display_id; 
                //         }
                //         return '-';
                //     } 
                // },
                { key: "tenancy_id", sortable: false, label: "Tenancy ID" },
                { key: "ctc.status", sortable: false, label: "Status",
                    formatter: value => {
                        if (value != null) {
                            return value.charAt(0).toUpperCase() + value.slice(1); 
                        }
                        return '-';
                    } 
                },
                { key: "ctc_created_at", sortable: false, label: "Request Date" },
                { key: "shipping", sortable: false, label: "Shipping Details" },
                { key: "action" },
            ],
            statusOptions: [
                { name: 'All', value: 'all' },
                { name: 'Paid', value: 'paid' },
                { name: 'Shipped', value: 'shipped' },
                { name: 'Delivered', value: 'delivered' },
                { name: 'Pending Payment', value: 'pending_payment' },
            ],
        }
    },
    methods: {
        setAdminView() {
            // this.filter = Object.assign(this.filter, filter);
        },
        // badgeClass(val) {
        //     return badgeColor("ctc", val);
        // },
        // badgeText(val) {
        //     return statusLabel(val);
        // },
        filterTable() {
            const filter = this.filter;
            this.$refs.listingTable.filterTable(filter);
        },
        deleteRecord(id, tenancy_id) {
            const deleteCallback = async () => {
                const result = await axios.delete(`${process.env.VUE_APP_APIURL}/order/${id}`, {
                    headers: authHeader(),
                });
                if (result.data.code === 'success') {
                    this.filterTable();
                    this.$refs.confirmationModal.closeModal();
                    this.$refs.messageModal.showModal('The record has been deleted');
                } else if (result.data.code === "permission_denied") {
                    this.$refs.confirmationModal.closeModal();
                    this.$refs.messageModal.showModal("Permission Denied");
                } 
            }
            this.$refs.confirmationModal.showModal(`Confirm delete tenancy (ID: ${tenancy_id})?`, deleteCallback);
        },
        resetTable() {
            this.filter = {
                "order_display_id": '',
                "parent_id": "",
                "status": 'all',
                "created": ""
            };
            this.$refs.listingTable.filterTable(this.filter);
        }
    }
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />

        <div class="row">
            <div class="col-lg-12">
                <div class="card" style="border-radius: 15px;">
                    <div class="card-body pt-0">
                        <div class="row mt-2">
                            <div class="col-sm-12 col-md-3 col-lg-3 mt-2">
                                <label for="ctc_id" class="mb-0">ID</label>
                                <input type="text" placeholder="ID" name="ctcID" id="ctc_id" class="form-control" v-model="filter.order_display_id">
                            </div>

                            <div class="col-sm-12 col-md-3 col-lg-3 mt-2">
                                <label for="tenancy_id" class="mb-0">Tenancy ID</label>
                                <input type="text" placeholder="Tenancy ID" name="tenancyID" id="tenancy_id" class="form-control" v-model="filter.parent_id">
                            </div>

                            <div class="col-sm-12 col-md-3 col-lg-3 mt-2">
                                <SelectGroup id="status" displayName="Status" v-model="filter.status" :options="statusOptions" />
                            </div>

                            <div class="col-sm-12 col-md-3 col-lg-3 mt-2">
                                <InputGroup type="date" id="created" displayName="Request Date" v-model="filter.created" />
                            </div>

                            <div class="col-12 d-flex justify-content-end" style="margin-top: 12px;">
								<a href="javascript:void(0);" class="btn btn-orange" @click="filterTable">
									Filter
								</a>
								<a href="javascript:void(0);" class="btn btn-filter" style="margin-left: 11px;" @click="resetTable">
									Reset
								</a>
							</div>
                            <div class="col-12">
                                <ListingTable
                                ref="listingTable"
                                :fields="fields"
                                :setAdminView="setAdminView"
                                path="ctc"
                                listName="ctcs"
                                >

                                    <!-- action -->
                                    <template v-slot:cell(action)="{ item }">
                                        <router-link
                                        class="mr-2 grey-icon-color"
                                        :to="{ name: 'Ctc View', params: { id: item.id } }">
                                            <i class="ri-eye-line font-size-18" v-b-tooltip.hover title="View"></i>
                                        </router-link>

                                        <!-- <router-link
                                        class="mr-3 text-primary" v-b-tooltip.hover
                                        title="Edit"
                                        :to="{ name: 'Ctc View', params: { id: item.id } }">
                                            <i class="ri-pencil-line font-size-18"></i>
                                        </router-link> -->
                                    </template>

                                    <!-- status -->
                                    <template v-slot:cell(ctc.status)="row">
                                        <StatusBadge :status="row.value" type="ctc" v-if="row.value != ''"/>
                                        <!-- <div
                                            class="badge font-size-12"
                                            :class="badgeClass(row.value)"
                                        >
                                            {{ row.value ? row.value : '-' }}
                                        </div> -->
                                    </template>

                                    <template v-slot:cell(ctc.created_at)="row">
                                        {{ row.value ? row.value : '-' }}
                                    </template>

                                    <template v-slot:cell(tenancy_order_display_id.order_display_id)="row">
                                        {{ row.value ? row.value : '-' }}
                                    </template>

                                    <!-- <template v-slot:cell(tenancy_parent)="row">
                                        <router-link :to="{ name: 'Tenancy View New', params: { id: row.item.tenancy_parent.order_id} }" 
                                            class="font-size-12" v-if="row.item.tenancy_parent != null"
                                        >
                                            {{ row.item.tenancy_parent.order_display_id ? row.item.tenancy_parent.order_display_id : '-'}}
                                        </router-link>
                                    </template> -->

                                    <!-- shipping -->
                                    <template v-slot:cell(shipping)="row">
                                        <p class="mb-0">Courier Company: {{ row.item.courier_company }}</p>
                                        <p class="mb-0">Tracking No.: {{ row.item.shipping_tracking_no }}</p>
                                    </template>

                                    <template v-slot:cell(payment_method_id)="row">
                                        {{ row.value === "" ? "-" : row.value }}
                                    </template>

                                </ListingTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>